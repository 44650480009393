import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Services/PageBanner2';
import Footer from '../components/App/Footer';
import graphic from '../assets/images/services/cmmc-levels.png';
import graphic2 from '../assets/images/services/screen.png';
import { Link } from 'gatsby';

const Details = () => {
  return (
    <Layout>
      <div className='darknav'>
        <Navbar />
      </div>
      <PageBanner
        pageTitle='CMMC, An Introspective'
        // activePageText=""
      />
      <section className='services-details-area ptb-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='row'>
                <div className='col-lg-12'>
                  <h1>Introduction</h1>
                  <p className='lead mb-50'>
                    The United States Department of Defense (DoD) has developed a certification program to combat
                    potential cybersecurity encroachment by nefarious actors. The Cybersecurity Maturity Model
                    Certification (CMMC) program enables the DoD to standardize cybersecurity preparedness across the
                    defense industrial base (DIB). Program certification will become a requirement by 2026 for
                    contractors conducting business with the DoD. This white paper provides insight for those companies
                    who anticipate obtaining defense contracts, or have current contracts, to meet the requirements for
                    CMMC accreditation.
                  </p>

                  <h2>What is CMMC?</h2>
                  <p className='base '>
                    The CMMC program measures a federal contractors’ capabilities, readiness, and sophistication in the
                    area of cybersecurity. CMMC is a framework for the enforcement of existing Defense Federal
                    Acquisition Regulation Supplement (DFARS) requirements developed in 2017 to provide security
                    protection for Controlled Unclassified Information (CUI). The framework also includes cybersecurity
                    contributions from the National Institute of Standards and Technology (NIST) and the Federal
                    Acquisition Regulation (FAR). The program’s main focus is to enhance the surety and security of CUI
                    and Federal Contract Information (FCI) expended by federal contractors. Prospective federal
                    contractors must complete a CMMC System Security Plan (SSP) in order to be considered for inclusion
                    in the program. Based on the information submitted in the SSP the DoD will assign a certification
                    level to the contract(s) issued to the authorized business entity.
                  </p>
                  <div className='row'>
                    <div className='col-lg-5 ptb-40' style={{ alignItems: 'center', display: 'flex' }}>
                      <img src={graphic} style={{ width: '100%' }} alt='CMMC Levels' />
                    </div>
                    <div className='col-lg-7  ptb-100'>
                      <h2>Certification Levels</h2>
                      <p className='base'>
                        The CMMC program contains 5 levels of maturity. The cybersecurity requirements become more
                        advanced as the levels increase.{' '}
                      </p>
                      <ul className='details-list'>
                        <li>
                          Level 1 is “basic cybersecurity”. This level has a small subset of NIST 800‐171‐based data
                          controls and other “best practices” that include anti‐virus protocols, FAR requirements, and
                          ad hoc incident response capabilities.
                        </li>
                        <li>
                          Levels 2 requires “intermediate cybersecurity” and includes Level 1 components as well as
                          awareness and training standards, risk management assessments, security continuity, and backup
                          protocols.
                        </li>
                        <li>
                          Level 3 contains “good cybersecurity” practices that include level 1 and 2 components in
                          addition to compliance with all NIST 800‐171 requirements, the ability to share threat
                          information with key stakeholders, and multi‐factor authentication (MFA) capabilities.
                        </li>
                        <li>
                          Level 4 must maintain “proactive cybersecurity controls” that include level 1, Page 4 | 5 2,
                          and 3 components in addition to network segmentation, detonation chambers, mobile device
                          inclusion, DLP technology, supply chain risk consideration, and threat hunting proficiencies.
                        </li>
                        <li>
                          Level 5 is the highest node of “advanced cybersecurity protection”. This level includes
                          everything from the other 4 levels as well as 24/7 SOC operations, device authentication,
                          cyber maneuver operations, organizational custom protections, and real‐time asset tracking.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <h2>System Security Plan Compliance</h2>
                      <p className='base-paragraph'>
                        As previously referenced, each prospective contractor must submit an SSP to become CMMC
                        certified. The SSP contains 17 sections or “Domains” with a subset of 171 “Practices”. The
                        prospective contractor must describe how they plan to manage and maintain each Domain within
                        their organization by providing information around how they will handle each Practice within a
                        Domain.Here is an overview of the SSP framework reflecting the Domains and corresponding
                        Practices.
                      </p>
                      <h2>Applicability</h2>
                      <p className='base-paragraph'>
                        CMMC accreditation applies to any contractor (primary or sub) who engage with the DoD to fulfill
                        federal contracts. Although some level of certification will be a requirement of every contract
                        starting in 2026, contracts will be issued at all 5 levels of the maturity model. As such, some
                        contracts will require only a low level of certification, while others will require a higher
                        certification level, depending on the cybersecurity assessment established by the DoD for a
                        particular contract.
                      </p>
                    </div>

                    <div className='col-lg-4'>
                      <img src={graphic2} style={{ width: '100%' }} alt='CMMC Levels' />
                    </div>

                    <div className='col-lg-8'>
                      <h2>Why CMMC?</h2>
                      <p className='base-paragraph'>
                        CMMC serves as a verification tool to ensure appropriate cybersecurity practices are being
                        adhered to by every DoD contractor. The Department of Defense is migrating to the CMMC framework
                        in order to assess, regulate and enhance the cybersecurity stance of their contractors. The
                        program will establish a verification process to ensure appropriate cybersecurity practices are
                        enforced. The intent is to confirm that basic cyber security controls are protecting controlled
                        unclassified information (CUI) used and maintained by those contractors working with the DoD.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <h2>Key Considerations</h2>
                      <p className='base-paragraph mb-0'>
                        Some things each company must consider with determining whether they should become certified.
                      </p>
                      <ul className='details-list'>
                        <li>Applies to DoD prime contractors and subcontractors;</li>
                        <li>
                          Applies to some new contracts starting in 2020 and applies to all contracts beginning in 2026;
                        </li>
                        <li>
                          The progressive model covers advancing levels of cybersecurity processes and practices
                          resulting in a certification level;
                        </li>
                        <li>
                          Contractors must start at level 1 and certify at each level in order to obtain contracts for
                          those associated levels established by the DoD.
                        </li>
                      </ul>
                      <h3 className='subheading-left'>What Level will my Organization Need?</h3>
                      <p className='base-paragraph'>
                        CMMC levels required for primary contractors will be specified in Requests For Information
                        (RFIs) and Requests for Proposals (RFPs). The level of certification for sub‐contractors depends
                        upon the type and nature of the information they receive from the primary contractor. Based on
                        DoD examples, primary contractors will likely need to achieve at least Level 3, while
                        sub‐contractors may only need to obtain Level 1.
                      </p>
                      <h3 className='subheading-left'>Does the Organization need just one Certification?</h3>
                      <p className='base-paragraph'>
                        A contractor can achieve a specific CMMC level for its entire enterprise or for a particular
                        segment, depending on where the information to be protected is managed and maintained within
                        their environment. An organization can decide to attain a base CMMC level for the entire
                        organization and be certified at higher levels for certain segments based on the requirements of
                        the contract.
                      </p>
                      <h3 className='subheading-left'>How Ofter is Recertification Required?</h3>
                      <p className='base-paragraph'>
                        Current DoD guidelines stipulate that CMMC accreditation will be valid for three years.{' '}
                      </p>
                    </div>
                    <div className='col-lg-12 mb-50'>
                      <h2>Obtaining CMMC Accrediation</h2>
                      <p className='base-paragraph'>
                        DoD has created the{' '}
                        <a href='https://cmmcab.org/' target='_blank' rel='noreferrer'>
                          CMMC Accreditation Body
                        </a>
                        (AB) which is a non‐profit, independent organization to certify Third Party Assessment
                        Organizations (3PAOs) in addition to individual assessors. Details are forthcoming about the
                        mechanics of certification, but DoD plans to establish a marketplace for 3PAOs to be evaluated
                        and hired by contractors seeking certification.
                      </p>

                      <h2>Ekko is here to Help</h2>
                      <p className='base-paragraph'>
                        Getting started with CMMC might seem to be a formidable task, and the reality is that
                        certification is just too large to be addressed by one person or even a team within an
                        organization. Nevertheless, certification will be a non‐negotiable requirement of DoD
                        contractors moving forward. EKKO Consulting can help you get started.
                      </p>
                      <Link to='/contact' activeClassName='active' className='default-btn'>
                        <i className='flaticon-right'></i> Request Demo <span></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr className='mtb-70' />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  );
};

export default Details;
