import React from 'react'
import Particles from 'react-particles-js';


const Bubbles2 = () => {
    return (
       <Particles
		style={{position: 'absolute',  opacity: .4, top: 0, left: '0%', right: '0%', }}
	
			params={
				{
                "autoPlay": true,
                "background": {
                    "color": {
                    "value": "#0d47a1"
                    },
                    "image": "",
                    "position": "50% 50%",
                    "repeat": "no-repeat",
                    "size": "cover",
                    "opacity": .0
                },
                "backgroundMask": {
                    "composite": "destination-out",
                    "cover": {
                    "color": {
                        "value": "#fff"
                    },
                    "opacity": 1
                    },
                    "enable": false
                },
                "backgroundMode": {
                    "enable": false,
                    "zIndex": -1
                },
                "detectRetina": true,
                "emitters": {
                    "direction": "left",
                    "life": {},
                    "rate": {
                    "quantity": 1,
                    "delay": 0
                    },
                    "size": {
                    "mode": "percent",
                    "height": 600,
                    "width": 600
                    },
                    "position": {
                    "x": 0,
                    "y": 100
                    }
                },
                "fpsLimit": 60,
                "infection": {
                    "cure": false,
                    "delay": 0,
                    "enable": false,
                    "infections": 0,
                    "stages": []
                },
                "interactivity": {
                    "detectsOn": "canvas",
                    "events": {
                    "onClick": {
                        "enable": false,
                        "mode": "push"
                    },
                    "onDiv": {
                        "selectors": [],
                        "enable": false,
                        "mode": [],
                        "type": "circle"
                    },
                    "onHover": {
                        "enable": false,
                        "mode": "repulse",
                        "parallax": {
                        "enable": false,
                        "force": 2,
                        "smooth": 10
                        }
                    },
                    "resize": true
                    },
                    "modes": {
                    "attract": {
                        "distance": 200,
                        "duration": 0.4,
                        "speed": 1
                    },
                    "bounce": {
                        "distance": 200
                    },
                    "bubble": {
                        "distance": 800,
                        "duration": 2,
                        "opacity": 0.8,
                        "size": 40
                    },
                    "connect": {
                        "distance": 80,
                        "links": {
                        "opacity": 0.5
                        },
                        "radius": 60
                    },
                    "grab": {
                        "distance": 400,
                        "links": {
                        "blink": false,
                        "consent": false,
                        "opacity": 1
                        }
                    },
                    "light": {
                        "area": {
                        "gradient": {
                            "start": {
                            "value": "#ffffff"
                            },
                            "stop": {
                            "value": "#000000"
                            }
                        },
                        "radius": 1000
                        },
                        "shadow": {
                        "color": {
                            "value": "#000000"
                        },
                        "length": 2000
                        }
                    },
                    "push": {
                        "quantity": 4
                    },
                    "remove": {
                        "quantity": 2
                    },
                    "repulse": {
                        "distance": 200,
                        "duration": 0.4,
                        "speed": 1
                    },
                    "slow": {
                        "factor": 3,
                        "radius": 200
                    },
                    "trail": {
                        "delay": 1,
                        "quantity": 1
                    }
                    }
                },
                "manualParticles": [],
                "motion": {
                    "disable": false,
                    "reduce": {
                    "factor": 4,
                    "value": false
                    }
                },
                "particles": {
                    "bounce": {
                    "horizontal": {
                        "random": {
                        "enable": false,
                        "minimumValue": 0.1
                        },
                        "value": 1
                    },
                    "vertical": {
                        "random": {
                        "enable": false,
                        "minimumValue": 0.1
                        },
                        "value": 1
                    }
                    },
                    "collisions": {
                    "bounce": {
                        "horizontal": {
                        "random": {
                            "enable": false,
                            "minimumValue": 0.1
                        },
                        "value": 1
                        },
                        "vertical": {
                        "random": {
                            "enable": false,
                            "minimumValue": 0.1
                        },
                        "value": 1
                        }
                    },
                    "enable": false,
                    "mode": "bounce"
                    },
                    "color": {
                    "value": "#ffffff",
                    "animation": {
                        "enable": false,
                        "speed": 1,
                        "sync": true
                    }
                    },
                    "life": {
                    "count": 0,
                    "delay": {
                        "random": {
                        "enable": false,
                        "minimumValue": 0
                        },
                        "value": 0,
                        "sync": false
                    },
                    "duration": {
                        "random": {
                        "enable": false,
                        "minimumValue": 0.0001
                        },
                        "value": 0,
                        "sync": false
                    }
                    },
                    "links": {
                    "blink": false,
                    "color": {
                        "value": "#ffffff"
                    },
                    "consent": false,
                    "distance": 150,
                    "enable": false,
                    "frequency": 1,
                    "opacity": 0.4,
                    "shadow": {
                        "blur": 5,
                        "color": {
                        "value": "#00ff00"
                        },
                        "enable": false
                    },
                    "triangles": {
                        "enable": false,
                        "frequency": 1
                    },
                    "width": 1,
                    "warp": false
                    },
                    "move": {
                    "angle": {
                        "offset": 45,
                        "value": 90
                    },
                    "attract": {
                        "enable": false,
                        "rotate": {
                        "x": 600,
                        "y": 1200
                        }
                    },
                    "direction": "none",
                    "distance": 0,
                    "enable": true,
                    "gravity": {
                        "acceleration": 9.81,
                        "enable": false,
                        "maxSpeed": 36
                    },
                    "noise": {
                        "delay": {
                        "random": {
                            "enable": false,
                            "minimumValue": 0
                        },
                        "value": 0
                        },
                        "enable": false
                    },
                    "outModes": {
                        "default": "destroy",
                        "bottom": "destroy",
                        "left": "destroy",
                        "right": "destroy",
                        "top": "destroy"
                    },
                    "random": false,
                    "size": false,
                    "speed": 5,
                    "straight": false,
                    "trail": {
                        "enable": false,
                        "length": 10,
                        "fillColor": {
                        "value": "#000000"
                        }
                    },
                    "vibrate": false,
                    "warp": false
                    },
                    "number": {
                    "density": {
                        "enable": true,
                        "area": 800,
                        "factor": 1000
                    },
                    "limit": 0,
                    "value": 0
                    },
                    "opacity": {
                        "random": {
                            "enable": false,
                            "minimumValue": 0.1
                        },
                        "value": 0.3,
                        "animation": {
                            "enable": false,
                            "minimumValue": 0.1,
                            "speed": 3,
                            "sync": false
                        }
                        },
                        "reduceDuplicates": false,
                        "rotate": {
                        "random": {
                            "enable": false,
                            "minimumValue": 0
                        },
                        "value": 0,
                        "animation": {
                            "enable": false,
                            "speed": 0,
                            "sync": false
                        },
                        "direction": "clockwise",
                        "path": false
                        },
                        "shadow": {
                        "blur": 0,
                        "color": {
                            "value": "#000000"
                        },
                        "enable": false,
                        "offset": {
                            "x": 0,
                            "y": 0
                        }
                    },
                    "shape": {
                   
                    "type": "circle"
                    },
                    "size": {
                    "random": {
                        "enable": false,
                        "minimumValue": 1
                    },
                    "value": 6,
                    "animation": {
                        // "destroy": "max",
                        "enable": true,
                        "minimumValue": 0.1,
                        "speed":4,
                        "startValue": "min",
                        "sync": true
                    }
                    },
                    "stroke": {
                    "width": 0,
                    "color": {
                        "value": "#000000",
                        "animation": {
                        "enable": false,
                        "speed": 1,
                        "sync": true
                        }
                    }
                    },
                    "twinkle": {
                    "lines": {
                        "enable": false,
                        "frequency": 0.05,
                        "opacity": 1
                    },
                    "particles": {
                        "enable": false,
                        "frequency": 0.05,
                        "opacity": 1
                    }
                    }
                },
                "pauseOnBlur": true,
                "pauseOnOutsideViewport": false,
                "themes": []
                }
			}
        //  params={
					// {
					// 	"particles": {
					// 		"number": {
					// 			"value": 160,
					// 			"density": {
					// 				"enable": false
					// 			}
					// 		},
					// 		"size": {
					// 			"value": 3,
					// 			"random": true,
					// 			"anim": {
					// 				"speed": 3,
					// 				"size_min": 0.3
					// 			}
					// 		},
					// 		"line_linked": {
					// 			"enable": false
					// 		},
					// 		"move": {
					// 			"random": true,
					// 			"speed": 1,
					// 			"direction": "left",
					// 			"out_mode": "out"
					// 		}
					// 	},
						// "interactivity": {
						// 	"events": {
						// 		"onhover": {
						// 			"enable": true,
						// 			"mode": "bubble"
						// 		},
						// 		"onclick": {
						// 			"enable": true,
						// 			"mode": "repulse"
						// 		}
						// 	},
						// 	"modes": {
						// 		"bubble": {
						// 			"distance": 250,
						// 			"duration": 2,
						// 			"size": 0,
						// 			"opacity": 0
						// 		},
						// 		"repulse": {
						// 			"distance": 400,
						// 			"duration": 4
						// 		}
						// 	}
						// }
					// }
             
         		// }
       />
    )
}

export default Bubbles2


